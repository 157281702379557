<template>
  <div class="tabs" ref="tabs">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="tab"
      :class="{ 'tab-active': tabActive(item.id) }"
      @click="onSelect(item.id)"
    >
      {{ item.name }}
      <div class="divider" v-if="isShowDivider(index)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    items: {
      type: Array,
      default: () => [],
    },
    isStartLeft: {
      type: Boolean,
      default: false,
    },
    startFrom: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      offsetLeftActive: 0,
      offsetTopActive: 0,
      widthActive: 0,
      heightActive: 0
    };
  },

  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    left() {
      if (!this.offsetLeftActive && this.startFrom) return this.startFrom;
      if (!this.offsetLeftActive) return "3px";
      return `${this.offsetLeftActive}px`;
    },
    top() {
      if (!this.offsetTopActive) return "3px";
      return `${this.offsetTopActive}px`;
    },
    height() {
      if (!this.heightActive) return "40px";
      return `${this.heightActive}px`;
    },
    width() {
      if (!this.widthActive && this.isStartLeft) return "0%";
      if (!this.widthActive) return "100%";
      return `${this.widthActive}px`;
    }
  },

  mounted() {
    window.addEventListener("resize", this.getPositionActiveTab);
    this.getPositionActiveTab();
    setTimeout(() => {
      this.getPositionActiveTab();
    }, 300);
  },

  watch: {
    currentValue() {
      this.getPositionActiveTab();
    },
    items() {
      this.getPositionActiveTab();
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getPositionActiveTab);
  },

  methods: {
    getPositionActiveTab() {
      this.$nextTick(() => {
        const refs = this.$refs.tabs;
        if (!refs) return;
        const elements = refs.getElementsByClassName("tab-active");
        if (!elements.length) return;
        this.offsetLeftActive = elements[0].offsetLeft || 0;
        this.offsetTopActive = elements[0].offsetTop || 0;
        this.widthActive = elements[0].offsetWidth || 0;
        this.heightActive = elements[0].offsetHeight || 0;
      });
    },

    onSelect(val) {
      if (this.currentValue === val) return;
      this.currentValue = val;
    },

    tabActive(index) {
      return this.currentValue === index;
    },

    isShowDivider(index) {
      const lastItem = this.items.length - 1 === index;
      const currentItem = this.currentValue === index;
      const previousItem = this.currentValue - 1 === index;

      return !lastItem && !currentItem && !previousItem;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 3px;
  border-radius: 8px;
  border: 1px solid #1d1e2f;
  width: fit-content;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    background: #15162b;
    left: v-bind(left);
    top: 3px;
    width: v-bind(width);
    height: calc(100% - 6px);
    border-radius: 6px;
    transition: left 0.6s ease, width 0.3s ease;
  }
}

.tab {
  position: relative;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s ease background-color;

  &.tab-active {
    font-weight: 600;
    color: #ffffff;
  }
}

.divider {
  position: absolute;
  top: 50%;
  right: -5px;
  width: 1px;
  height: 24px;
  background: #15162b;
  margin: 0;
  transform: translateY(-50%);
}

@media all and (max-width: 599px) {
  .tabs {
    flex-direction: column;
    width: 100%;
    gap: 0;
    &:before {
      height: v-bind(height);
      top: v-bind(top);
      left: 3px;
      transition: top 0.6s ease, height 0.3s ease;
    }
  }
  .divider {
    width: 100%;
    right: 0;
    height: 1px;
    bottom: 0;
    top: unset;
  }
}
</style>
