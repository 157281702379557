<template>
  <b-button class="b-btn-close" @click="$emit('click')">
    <close-icon />
  </b-button>
</template>

<script>
import CloseIcon from "@/components/Icons/CloseIcon";

export default {
  name: "CloseButton",
  components: {
    CloseIcon,
  },
};
</script>

<style lang="scss" scoped>
.b-btn-close {
  position: absolute;
  right: -40px;
  width: auto;
  height: auto;
  padding: 0;
  border-radius: 50px;
  background-color: transparent;
}
</style>
