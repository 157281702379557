import axios from 'axios'

let baseURL = '';
if (process.env.NODE_ENV == 'development')
    baseURL = 'https://clientportalapi-dev.candidcrm.com/'
else if (process.env.NODE_ENV == "staging")
    baseURL = 'https://clientportalapi-staging.candidcrm.com/'
else
    baseURL = 'https://clientportalapi.candidcrm.com/'

export default axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});