<template>
  <div class="home-screen">
    <div class="mortgage-section mortgage-left">
      <div class="mortgage-section--header">
        <div>
          <h2 class="mb-0">Garrett’s Mortgage Estimate</h2>
          <p>Quote date - Nov 18, 2021</p>
        </div>
        <outlined-button @click="moreInfo" text="More info" />
      </div>

      <div class="mortgage-section--left">
        <mortgage-card
          v-for="(item, idx) in mortgageCardData"
          @mouseover="onHover(idx)"
          @mouseout="onOut()"
          :key="idx"
          :item="item"
        />
      </div>
    </div>

    <div class="mortgage-section mortgage-right">
      <div class="mortgage-section--header">
        <div class="mortgage-right--title">
          <h2 class="mb-0">{{ paymentData.title }}</h2>
          <p>{{ paymentData.subTitle }}</p>
        </div>
        <outlined-button text="More info" />
      </div>
      <bar-chart-rounded
        v-model="paymentData.chartData"
        :labels="Object.values(scenarioKeyMap)"
        :activeId="activeId"
      />

      <div class="d-flex justify-content-center">
        <BaseTabs v-model="tab" :items="paymentOptions" />
      </div>
    </div>
    <more-info-dialog
      :moreInfoData="moreInfoData"
      :paymentBreakdownChart="paymentBreakdownChart"
      :cashToCloseBreakdownChart="cashToCloseBreakdownChart"
      :scenarioKeyMap="scenarioKeyMap"
      @learnMore="learnMore"
      ref="moreInfo"
    />
    <fee-detail-dialog
      :mortgageData="mortgageData"
      @closeDetail="moreInfo(1)"
      ref="feeDetail"
    />
  </div>
</template>

<script>
import OutlinedButton from "@/components/commons/atoms/OutlinedButton";
import BarChartRounded from "@/components/Charts/BarChartRounded";
import MoreInfoDialog from "@/components/home-pages/MoreInfoDialog";
import BaseTabs from "@/components/mortgage-estimate/BaseTabs";
import FeeDetailDialog from "@/components/home-pages/FeeDetailDialog";
import MortgageCard from "@/components/home-pages/MortgageCard";
import mortgageData from "@/mock/Prospect_Scenarios_Response.json";
import { PaymentContent } from "@/components/mortgage-estimate/MortgageEstimate";
import camelCase from "lodash/camelCase";

export default {
  name: "MortgageEstimate",
  components: {
    OutlinedButton,
    BarChartRounded,
    MoreInfoDialog,
    BaseTabs,
    FeeDetailDialog,
    MortgageCard,
  },
  data() {
    return {
      mortgageData: [],
      mortgageCardData: [],
      moreInfoData: {
        paymentBreakdown: [],
        cashToCloseBreakdown: [],
        paymentBreakdownField: [
          {
            key: "paymentBreakdown",
            label: "Payment breakdown",
          },
        ],
        cashToCloseBreakdownField: [
          {
            key: "cashToCloseBreakdown",
            label: "Cash to close",
          },
        ],
      },
      paymentBreakdownChart: [],
      cashToCloseBreakdownChart: [],
      scenarioKeyMap: {},
      modal: true,
      tab: 0,
      activeId: null,
      paymentData: {
        title: "",
        subTitle: "",
        chartData: [],
      },
      paymentOptions: [],
    };
  },
  created() {
    this.mortgageData = mortgageData.scenarios;

    // handle get mortgage cart
    this.mortgageCardData = this.mortgageData.map((e) => ({
      name: e.name,
      totalPayment: e.totalPayment,
      purchasePrice: e.purchasePrice,
      loanAmount: e.loanAmount,
      loanType: e.loanType,
      downPayment: e.downPayment,
      downPaymentRate: e.downPaymentRate,
      interestRate: e.interestRate,
      cashToClose: e.cashToClose,
      apr: e.apr,
    }));

    // handle get scenario key
    this.mortgageData.forEach((e) => {
      this.scenarioKeyMap[camelCase(e.name)] = e.name;
    });

    // handle get field
    this.moreInfoData.paymentBreakdownField = this.handleGetField(
      this.moreInfoData.paymentBreakdownField,
      this.mortgageData
    );

    this.moreInfoData.cashToCloseBreakdownField = this.handleGetField(
      this.moreInfoData.cashToCloseBreakdownField,
      this.mortgageData
    );

    // handle payment breakdown
    const paymentBreakdownKeyMap = {
      purchasePrice: "Purchase Price",
      pi: "P&I (1st)",
      propertyTaxes: "Property Taxes",
      hoi: "HOI",
      pmi: "PMI",
      monthlyPayment: "Monthly Payment",
      hoa: "HOA",
      totalPayment: "Total Payment",
    };
    const paymentRows = [
      {
        paymentBreakdown: "Purchase Price",
      },
      {
        paymentBreakdown: "P&I (1st)",
      },
      {
        paymentBreakdown: "Property Taxes",
      },
      {
        paymentBreakdown: "HOI",
        info: "Homeowner’s Insurance",
      },
      {
        paymentBreakdown: "PMI",
        info: "Private Mortgage Insurance",
      },
      {
        paymentBreakdown: "Monthly Payment",
      },
      {
        paymentBreakdown: "HOA",
      },
      {
        paymentBreakdown: "Total Payment",
      },
    ];

    this.moreInfoData.paymentBreakdown = this.handleGetInfoData(
      this.scenarioKeyMap,
      paymentBreakdownKeyMap,
      paymentRows,
      this.mortgageData,
      "paymentBreakdown"
    );

    // handle cash to close

    const cashToCloseKeyMap = {
      downPmt: "Down Pmt",
      loanToValue: "Loan to Value",
      closingCost: "Closing Costs",
      points: "Points",
      sp: "SP",
      prepaid: "Prepaids",
      cashToClose: "Cash to Close",
      feeDetails: "Fee Detail",
    };
    const cashRows = [
      {
        cashToCloseBreakdown: "Down Pmt",
      },
      {
        cashToCloseBreakdown: "Loan to Value",
      },
      {
        cashToCloseBreakdown: "Closing Costs",
      },
      {
        cashToCloseBreakdown: "Points",
      },
      {
        cashToCloseBreakdown: "SP",
        info: "Private Mortgage Insurance",
      },
      {
        cashToCloseBreakdown: "Prepaids",
      },
      {
        cashToCloseBreakdown: "Cash to Close",
      },
      {
        cashToCloseBreakdown: "Fee Detail",
      },
    ];
    this.moreInfoData.cashToCloseBreakdown = this.handleGetInfoData(
      this.scenarioKeyMap,
      cashToCloseKeyMap,
      cashRows,
      this.mortgageData,
      "cashToCloseBreakdown"
    );

    this.paymentBreakdownChart = this.mortgageData.map((e) => ({
      pi: e.paymentBreakdown.pi,
      propertyTaxes: e.paymentBreakdown.propertyTaxes,
      hoi: e.paymentBreakdown.hoi,
      pmi: e.paymentBreakdown.pmi,
    }));

    this.cashToCloseBreakdownChart = this.mortgageData.map((e) => ({
      downPmt: e.cashToCloseBreakdown.downPmt,
      closingCost: e.cashToCloseBreakdown.closingCost,
      points: e.cashToCloseBreakdown.points,
      sp: e.cashToCloseBreakdown.sp,
      prepaid: e.cashToCloseBreakdown.prepaid,
    }));
  },
  watch: {
    tab: {
      handler(val) {
        const paymentComparisonData = this.getPaymentTermComparisons(
          mortgageData.scenarios,
          val
        );
        this.paymentOptions = this.buildPaymentOptions(mortgageData.scenarios);

        this.paymentData = {
          title: this.paymentOptions[val].name,
          subTitle: this.paymentOptions[val].description,
          chartData: paymentComparisonData.map((e) => e.details.totalPayment),
        };
      },
      immediate: true,
    },
  },
  methods: {
    onHover(idx) {
      this.activeId = idx;
    },
    onOut() {
      this.activeId = null;
    },
    moreInfo(tabIndex) {
      this.$refs.moreInfo.open(tabIndex);
    },
    learnMore(items) {
      this.$refs.moreInfo.close();
      this.$refs.feeDetail.open(items);
    },
    handleGetField(field, mortgageData) {
      const fieldData = [
        ...field,
        ...mortgageData.map((e) => ({
          key: camelCase(e.name),
          label: e.name,
          sortable: true,
        })),
      ];
      return fieldData;
    },
    handleGetInfoData(
      scenarioKeyMap,
      breakdownKeyMap,
      rows,
      mortgageData,
      field
    ) {
      mortgageData.map((e) => {
        const scenarioIdx = this.getKey(scenarioKeyMap, e.name);
        const scenarioKey = Object.keys(scenarioKeyMap)[scenarioIdx];
        rows.map((r) => {
          const breakdownIdx = this.getKey(breakdownKeyMap, r[field]);
          const breakdownKey = Object.keys(breakdownKeyMap)[breakdownIdx];
          r[scenarioKey] = e[field][breakdownKey];
        });
      });
      return rows;
    },
    getKey(arr, name) {
      return Object.values(arr).findIndex((e) => e == name);
    },
    getPaymentTermComparisons(scenariosData, paymentType) {
      const result = [];
      scenariosData.forEach((scenario) => {
        const paymentTermItem = scenario.paymentTermComparisons[paymentType];
        paymentTermItem.scenaiosName = scenario.name;
        result.push(paymentTermItem);
      });
      return result;
    },
    buildPaymentOptions(scenariosData) {
      return scenariosData[0].paymentTermComparisons.map((item, index) => ({
        name: item.name,
        description: PaymentContent[index].subTitle
          .replace(/{_MI_}/g, item.details.hasMI ? " and MI paid " : " ")
          .replace(/{_period_}/g, item.period),
        id: index,
      }));
    },
  },
};
</script>

<style lang="scss">
.home-screen {
  display: flex;
  justify-content: space-between;
  .mortgage-left {
    width: calc(55% - 6.5%);
  }
  .mortgage-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
    &--title {
      padding-left: 14px;
      padding-right: 38px;
      max-width: 687px;
    }
  }
  .mortgage-section {
    &--header {
      display: flex;
      justify-content: space-between;
      button {
        min-width: 132px;
        margin-top: 8px;
      }
      h2 {
        font-weight: 600;
        font-size: 25px;
        line-height: 38px;
      }
      p {
        opacity: 0.7;
      }
    }
    &--left {
      margin-top: 20px;

      display: grid;
      gap: 11px;
    }
  }
}
</style>
