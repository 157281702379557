<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" :fill="color" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8464 8.61552L19.3848 10.154L15.5388 14L19.385 17.8462L17.8466 19.3846L14.0004 15.5384L10.1542 19.3846L8.61572 17.8462L12.4619 14L8.61593 10.154L10.1544 8.61552L14.0004 12.4615L17.8464 8.61552Z"
      :fill="xColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
    xColor: {
      type: String,
      default: "#28253D",
    },
  },
};
</script>
