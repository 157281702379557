var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDebtDetails),expression:"showDebtDetails"}],staticClass:"button-switch"},[_c('label',{staticClass:"switch",attrs:{"for":"enable-disable"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.togglePayingOffDebts),expression:"togglePayingOffDebts"}],attrs:{"type":"checkbox","id":"enable-disable"},domProps:{"checked":Array.isArray(_vm.togglePayingOffDebts)?_vm._i(_vm.togglePayingOffDebts,null)>-1:(_vm.togglePayingOffDebts)},on:{"change":function($event){var $$a=_vm.togglePayingOffDebts,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.togglePayingOffDebts=$$a.concat([$$v]))}else{$$i>-1&&(_vm.togglePayingOffDebts=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.togglePayingOffDebts=$$c}}}}),_c('span',{staticClass:"slider round"})]),_c('span',{staticClass:"switch-text"},[_vm._v("Paying off debts")])]),_c('div',{staticClass:"more-info-payment-breakdown-table"},[_c('table',{staticClass:"table b-table",attrs:{"id":"loan-purpose-refinance-table","role":"table","aria-busy":"false","aria-colcount":"3"}},[_c('thead',{staticClass:"thead-info-active",attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},_vm._l((_vm.tableHeaders),function(header,index){return _c('th',{key:index,staticClass:"table-b-table-default",class:header.isCurrentMortgage
                ? 'position-relative add-dynamic-border-head'
                : '',attrs:{"role":"columnheader","scope":"col","aria-colindex":index + 1}},[_c('div',[_vm._v(_vm._s(header.name))])])}),0)]),_c('tbody',{staticClass:"more-info-payment-breakdown-table-active",attrs:{"role":"rowgroup"}},_vm._l((_vm.tableRows),function(rows,rowindex){return _c('tr',{key:rowindex,attrs:{"role":"row"}},[_vm._l((rows),function(value,key,cellindex){return [(
                typeof value === 'object' &&
                !Array.isArray(value) &&
                value !== null
              )?_c('td',{key:cellindex,class:[
                _vm.currentMortgageIndex >= 0 &&
                cellindex === _vm.currentMortgageIndex + 1
                  ? 'add-dynamic-border-body'
                  : '',
                rowindex === _vm.tableRows.length - 1 &&
                _vm.currentMortgageIndex >= 0 &&
                cellindex === _vm.currentMortgageIndex + 1
                  ? 'add-dynamic-border-body-last-el'
                  : '',
              ],attrs:{"aria-colindex":"1","role":"cell"}},_vm._l((value),function(subvalue,key,subcellindex){return _c('div',{key:subcellindex,class:subvalue === 'Additional Debts'
                    ? 'd-flex justify-space-between'
                    : 'add-margin-top'},[_c('span',{class:subvalue === 'Additional Debts'
                      ? ''
                      : 'extra-debt-details'},[_vm._v(_vm._s(_vm._f("currencyOrPercent")(_vm.convertIfNumber(subvalue))))]),(subvalue === 'Additional Debts')?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}},[(!_vm.showDebtTooltip)?_c('plus-icon'):_vm._e(),(_vm.showDebtTooltip)?_c('minus-icon'):_vm._e()],1):_vm._e(),(
                    subvalue !== 'Additional Debts' &&
                    typeof _vm.convertIfNumber(subvalue) === 'string'
                  )?_c('div',{staticClass:"tooltip-container"},[_c('tooltip-icon',{nativeOn:{"mouseenter":function($event){return _vm.fetchTooltipDetails(subvalue, subcellindex)},"mouseleave":function($event){_vm.tooltipIndex = null;
                      _vm.tooltipObject = {};}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tooltipIndex === subcellindex),expression:"tooltipIndex === subcellindex"}],staticClass:"tooltip"},[_c('strong',[_vm._v(_vm._s(_vm.tooltipObject.creditor))]),_vm._v(" Amount: $"+_vm._s(_vm.tooltipObject.balance)+" "),_c('br'),_vm._v(" Rate: "+_vm._s(_vm.tooltipObject.rate)+"%"),_c('br'),_vm._v(" Payment: $"+_vm._s(_vm.tooltipObject.payment)+" "),_c('br')])],1):_vm._e()])}),0):_c('td',{key:cellindex,class:[
                _vm.currentMortgageIndex >= 0 &&
                cellindex === _vm.currentMortgageIndex + 1
                  ? 'add-dynamic-border-body'
                  : '',
                rowindex === _vm.tableRows.length - 1 &&
                _vm.currentMortgageIndex >= 0 &&
                cellindex === _vm.currentMortgageIndex + 1
                  ? 'add-dynamic-border-body-last-el'
                  : '',
              ],attrs:{"aria-colindex":"1","role":"cell"}},[(value === 'Additional Debts')?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm._f("currencyOrPercent")(value)))]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}},[(!_vm.showDebtTooltip)?_c('plus-icon'):_vm._e(),(_vm.showDebtTooltip)?_c('minus-icon'):_vm._e()],1)]):_c('span',{class:rowindex === _vm.tableRows.length - 1 && cellindex !== 0
                    ? 'custom-badge'
                    : ''},[_vm._v(_vm._s(_vm._f("currencyOrPercent")(value)))]),(value === 'PMI' || value === 'HOI')?_c('info-tooltip',{attrs:{"info":value}}):_vm._e()],1)]})],2)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }