export default {
    methods: {
        getShadesOfColor(color, lum) {
            // validate hex string
            color = String(color).replace(/[^0-9a-f]/gi, "");

            if (color.length < 6) {
                color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
            }

            lum = lum || 0;

            // convert to decimal and change luminosity
            let rgb = "#";
            let c;

            if (color) {
                for (let i = 0; i < 3; i++) {
                    c = parseInt(color.substr(i * 2, 2), 16);
                    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
                    rgb += ("00" + c).substr(c.length);
                }
            }

            return rgb;
        },

        hexToRGBA(hex, opacity) {
            hex = hex.replace("#", "");
            // Extract the RGB values
            const red = parseInt(hex.substring(0, 2), 16);
            const green = parseInt(hex.substring(2, 4), 16);
            const blue = parseInt(hex.substring(4, 6), 16);

            // Create the RGBA color string
            return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
        },

        newShade(hexColor, magnitude) {
            hexColor = hexColor.replace(`#`, ``);
            if (hexColor.length === 6) {
                const decimalColor = parseInt(hexColor, 16);
                let r = (decimalColor >> 16) + magnitude;
                r > 255 && (r = 255);
                r < 0 && (r = 0);
                let g = (decimalColor & 0x0000ff) + magnitude;
                g > 255 && (g = 255);
                g < 0 && (g = 0);
                let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
                b > 255 && (b = 255);
                b < 0 && (b = 0);
                return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
            } else {
                return hexColor;
            }
        },

        hexToRgbColor(hex) {
            let c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("");
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = "0x" + c.join("");
                let rgb = [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
                this.rgbToFeColor(rgb);
            }
        },

        rgbToFeColor(rgb) {
            let rgbArray = rgb.split(",");
            rgbArray.forEach((element) => {
                let num = Number((element / 255).toFixed(2));
                let string = String("0 0 0 0 " + num.toString() + " ");
                this.feColorMatrixVal += string;
            });
            this.feColorMatrixVal += "0 0 0 0.33 0";
        },
    },
}