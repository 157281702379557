<template>
  <span>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C3.13395 0 0 3.13406 0 7C0 10.8662 3.13395 14 7 14C10.8661 14 14 10.8662 14 7C14 3.13406 10.8661 0 7 0ZM7 3.5C7.48327 3.5 7.875 3.89178 7.875 4.375C7.875 4.85844 7.48327 5.25 7 5.25C6.51673 5.25 6.125 4.85844 6.125 4.375C6.125 3.89178 6.51673 3.5 7 3.5ZM8.3125 10.5H5.6875C5.44589 10.5 5.25 10.3043 5.25 10.0625C5.25 9.82089 5.44589 9.625 5.6875 9.625H6.125V7H5.6875C5.44589 7 5.25 6.80433 5.25 6.5625C5.25 6.32089 5.44589 6.125 5.6875 6.125H7.4375C7.67911 6.125 7.875 6.32089 7.875 6.5625V9.625H8.3125C8.55411 9.625 8.75 9.82089 8.75 10.0625C8.75 10.3043 8.55411 10.5 8.3125 10.5Z"
        :fill="color || getAssets.primaryBrandColor || '#c34132'"
      />
    </svg>
  </span>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "InfoTooltipIcon",
  props: {
    color: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>
