<template>
  <div class="more-info-table">
    <b-table
      v-show="!isPaymentBreakdown"
      :id="
        getAssets.loanPurposeType === refinanceLoanType && isCashToClose
          ? 'loan-purpose-refinance-table'
          : 'bTable'
      "
      ref="moreInfoTable"
      :thead-class="{
        'thead-info-active': show,
      }"
      :tbody-class="{
        'more-info-table-active': show,
        'table-from-bottom': !isPaymentBreakdown,
      }"
      :tfoot-class="{
        'more-info-table-active': show,
        'foot-from-bottom': !isPaymentBreakdown,
      }"
      sticky-header
      :busy="loading"
      :items="value"
      :fields="fields"
    >
      <template v-if="isPdf" #head(description)="{ label }">
        <p class="label-pdf-detail">Fee Detail</p>
        <p class="label-pdf-header">{{ label }}</p>
      </template>

      <!--eslint-disable-->
      <template
        v-for="(field, index) in fields"
        #[`cell(${field.key})`]="{ item }"
      >
        <span
          v-if="
            item.cashToCloseBreakdown === 'SP' &&
            field.key === 'cashToCloseBreakdown'
          "
        >
          {{ getSpLabel() }}
        </span>
        <span
          v-else-if="
            (item.cashToCloseBreakdown === 'Seller Credit(s)' ||
              item.cashToCloseBreakdown === 'Earnest Money') &&
            field.key !== 'cashToCloseBreakdown'
          "
          class="green-fonts"
          >{{ item[field.key] | currencyOrPercent(item) }}</span
        >
        <span
          v-else-if="
            (item.cashToCloseBreakdown === 'Cash to Close' &&
              field.key !== 'cashToCloseBreakdown') ||
            (item.paymentBreakdown === 'Total Payment' &&
              field.key !== 'paymentBreakdown')
          "
          class="custom-badge"
          >{{ item[field.key] | currencyOrPercent(item) }}</span
        >
        <span v-else :class="{ 'status-no': item[field.key] <= 0 }">{{
          item[field.key] | currencyOrPercent(item)
        }}</span>
        <info-tooltip
          v-if="item[field.key] === 'PMI' || item[field.key] === 'HOI'"
          :info="item.info"
        />
        <!-- <info-tooltip
          v-if="item.cashToCloseBreakdown === 'SP'"
          :info="getDetailSP(index)"
        /> -->
      </template>

      <template #cell(paidBy)="{ item }">
        <span class="paid-by-col">{{ item.paidBy }}</span>
      </template>

      <template #cell(aprFee)="{ item }">
        <span :class="{ 'status-no': !item.aprFee }">{{
          item.aprFee ? "Yes" : "No"
        }}</span>
      </template>

      <template #cell(financed)="{ item }"
        ><span :class="{ 'status-no': !item.financed }">{{
          item.financed ? "Yes" : "No"
        }}</span>
      </template>

      <template #cell(total)="{ item }">
        <span :class="{ 'status-no': item.total <= 0 }">{{
          item.total | currencyAbsolute
        }}</span>
      </template>

      <template #cell(prepaidEscrows)="{ item }">
        <span :class="{ 'status-no': !item.prepaidEscrows }">{{
          item.prepaidEscrows ? "Yes" : "No"
        }}</span>
      </template>

      <template v-if="footers.length > 0" #custom-foot="{}">
        <td v-for="(ft, idx) in footers" :key="idx">
          <span @click="$emit('detail', ft)">{{ ft.label }}</span>
          <arrow-up-icon class="ml-2" v-if="idx !== 0" />
        </td>
      </template>
    </b-table>

    <more-info-payment-breakdown-table
      v-if="isPaymentBreakdown"
      :current-mortgage-index="currentMortgageIndex"
      :all-scenarios="allScenarios"
      :show-debt-tooltip="showDebtTooltip"
      @click="showHideTooltip"
    />
  </div>
</template>

<script>
import InfoTooltip from "@/components/Icons/InfoToolTip";
import ArrowUpIcon from "@/components/Icons/ArrowUpIcon";
import Vue from "vue";
import Shades from "@/mixins/shades";
import { mapGetters } from "vuex";
import { REFINANCE_LOAN_TYPE } from "./../../constants";
import MoreInfoPaymentBreakdownTable from "./MoreInfoPaymentBreakdownTable";

export default {
  components: { ArrowUpIcon, InfoTooltip, MoreInfoPaymentBreakdownTable },
  mixins: [Shades],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    footers: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    detailSP: {
      type: Array,
      default: () => [],
    },
    paddingX: {
      type: String,
      default: "16px",
    },
    paddingY: {
      type: String,
      default: "16px",
    },
    isPaymentBreakdown: {
      type: Boolean,
      default: false,
    },
    isCashToClose: {
      type: Boolean,
      default: false,
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
    currentMortgageIndex: {
      type: Number,
      default: 0,
    },
    allScenarios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      show: false,
      refinanceLoanType: REFINANCE_LOAN_TYPE,
      showDebtTooltip: false,
    };
  },
  beforeMount() {
    this.$nextTick(() => {
      let get_table = this.$refs?.moreInfoTable;

      if (get_table && this.currentMortgageIndex >= 0) {
        let get_headers =
          get_table.$el.children[0].children[0].children[0].children[
            this.currentMortgageIndex + 1
          ];

        if (get_headers) get_headers.classList.add("add-dynamic-border-head");

        let get_rows = get_table.$el.children[0].children[1].children;

        if (get_rows.length) {
          for (let i = 0; i < get_rows.length; i++) {
            get_rows[i].children[this.currentMortgageIndex + 1].classList.add(
              "add-dynamic-border-body"
            );

            if (i === get_rows.length - 1) {
              get_rows[i].children[this.currentMortgageIndex + 1].classList.add(
                "add-dynamic-border-body-last-el"
              );
            }
          }
        }
      }
    });
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 200);
  },
  methods: {
    showHideTooltip() {
      this.showDebtTooltip = !this.showDebtTooltip;
    },

    getDetailSP(index) {
      return this.detailSP[index - 1]?.ufmipFfGfSpLabel === undefined
        ? "Private Mortgage Insurance"
        : `${this.detailSP[index - 1]?.ufmipFfGfSpLabel || "SP"}: ${Vue.filter(
            "currencyOrPercent"
          )(this.detailSP[index - 1]?.ufmipFfGfSp)}`;
    },
    getSpLabel() {
      return this.detailSP[0]?.ufmipFfGfSpLabel || "SP";
    },
  },
  computed: {
    ...mapGetters(["getAssets"]),

    backgroundTotal() {
      return this.$localStore.get("primary-color")
        ? this.hexToRGBA(this.$localStore.get("primary-color"), 0.2)
        : "#FFCCCF";
    },
    colorTotal() {
      return this.$localStore.get("primary-color");
    },
  },
};
</script>

<style lang="scss">
@mixin v-padding {
  padding-left: v-bind(paddingX);
  padding-right: v-bind(paddingX);
  padding-top: v-bind(paddingY);
  padding-bottom: v-bind(paddingY);
}
@mixin base-table-properties {
  position: relative;
  top: 0;
  opacity: 0;
  transition: transform 1s, opacity 0.5s;
}

@media all and (max-width: 1099px) {
  .more-info-content:not(.layout-pdf) .more-info-table {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    &::-webkit-scrollbar {
      width: 7px !important;
      height: 7px !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #15162b !important;
      border: 5px solid #15162b !important;
      border-radius: 5px;
    }
  }
  .more-info-content:not(.layout-pdf)
    .more-info-table
    table
    thead
    tr
    th:first-child,
  .more-info-content:not(.layout-pdf)
    .more-info-table
    table
    tbody
    tr
    td:first-child,
  .fee-detail:not(.layout-pdf) .more-info-table table thead tr th:first-child,
  .fee-detail:not(.layout-pdf) .more-info-table table tbody tr td:first-child {
    padding-right: 16px !important;
    position: sticky !important;
    left: 0 !important;
    background: #fff !important;
    z-index: 999;
  }
}

@media all and (max-width: 899px) {
  .more-info-content:not(.layout-pdf) .more-info-table {
    margin-bottom: 24px !important;
    padding-bottom: 10px;
  }
}

@media all and (max-width: 599px) {
  .more-info-content:not(.layout-pdf)
    .more-info-table:not(.fee-detail--table)
    table
    thead
    tr
    th:not(:first-child)
    div {
    position: absolute;
    width: 118px;
    bottom: 20px;
  }
  .more-info-content:not(.layout-pdf)
    .more-info-table:not(.fee-detail--table)
    table
    thead
    tr
    th:not(:first-child) {
    min-width: 150px;
  }
  .more-info-content:not(.layout-pdf)
    .more-info-table
    table
    thead
    tr
    th:first-child
    div {
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: initial;
  }
  .more-info-content:not(.layout-pdf)
    .more-info-table
    table
    thead
    tr
    th:first-child {
    padding-right: 16px !important;
  }
}

.custom-badge {
  padding: 5px 8px;
  border-radius: 3px;
  color: v-bind(colorTotal);
  font-weight: 600;
  background-color: v-bind(backgroundTotal);
}

.more-info-table {
  margin-bottom: 8px;
  table {
    margin-bottom: 0;
    thead {
      opacity: 0;
      tr {
        th {
          @include v-padding;
          color: #000000b3;
          text-align: left;

          &:not(:first-child) {
            max-width: 150px;

            div {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          &:first-child {
            padding-right: 66px !important;
            padding-left: 0;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #000000;
            div {
              max-width: 210px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
    tbody {
      @include base-table-properties;
      transform: translateY(-5%);
      tr {
        box-shadow: none;
        border: none;
        td {
          @include v-padding;
          border-top: 1px solid #d7dae3;
          border-bottom: none;
          &:first-child {
            border-radius: 0;
            padding-left: 0;
            border-left: 0;
            font-weight: 600;
          }
          &:last-child {
            border-radius: 0;
            padding-right: 0;
            border-right: 0;
          }
        }
      }
    }

    tfoot {
      @include base-table-properties;
      transform: translateY(-45%);
      td {
        @include v-padding;
        border-top: 1px solid #d7dae3;
        border-bottom: none;
        svg {
          overflow: inherit;
          padding: 0;
        }
        &:not(:first-child) {
          padding-left: 8px;
          span {
            text-decoration: underline;
            font-weight: 500;
            color: #200e00;
            margin-right: 8px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        span {
          padding: 0;
        }
        &:first-child {
          border-radius: 0;
          padding-left: 0;
          border-left: 0;
          font-weight: 600;

          position: sticky !important;
          left: 0 !important;
          background: #fff !important;
          z-index: 999;
        }
        &:last-child {
          border-radius: 0;
          padding-right: 0;
          border-right: 0;
        }
      }
    }
  }

  #loan-purpose-refinance-table {
    border-spacing: 0;
    .add-dynamic-border-head {
      // background-color: #f2f5fa;
      border-radius: 10px 10px 0 0;
      padding-left: 20px;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      border-bottom: none;
      font-weight: 600;
    }
    .add-dynamic-border-body {
      // background-color: #f2f5fa;
      padding-left: 20px;
      border: 1px solid var(--primary-color);
      border-top: 1px solid #d7dae3;
      border-bottom: none;
    }
    .add-dynamic-border-body-last-el {
      border-radius: 0 0 10px 10px;
      border-bottom: 1px solid var(--primary-color);
    }
  }

  .paid-by-col {
    color: #15162b;
    padding: 5px 14px;
    background: rgba(21, 22, 43, 0.05);
    border-radius: 3px;
    font-weight: 600;
  }

  .status-no {
    opacity: 0.6;
  }

  .green-fonts {
    font-weight: 500;
    color: #04844b;
  }
}

.table-from-bottom {
  transform: translateY(5%) !important;
}

.foot-from-bottom {
  transform: translateY(45%) !important;
}

.more-info-table-active {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.thead-info-active {
  opacity: 1 !important;
}
</style>
