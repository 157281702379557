<template>
  <div class="m-auto">
    <welcome-dialog />
    <header-layout />
    <div class="home-page">
      <mortgage-estimate />
    </div>
    <footer-layout />
  </div>
</template>

<script>
import WelcomeDialog from "@/components/home-pages/WelcomeDialog";
import HeaderLayout from "@/components/layouts/HeaderLayout";
import FooterLayout from "@/components/layouts/FooterLayout";
import MortgageEstimate from "@/components/home-pages/MortgageEstimate";
import { mapActions } from "vuex";

export default {
  name: "HomePage",
  beforeRouteEnter(to, from, next) {
    if (parseInt(to.query.role) === 1) {
      return next('/mortgage-estimates')
    }
    next()
  },
  components: {
    WelcomeDialog,
    HeaderLayout,
    FooterLayout,
    MortgageEstimate,
  },
  mounted() {
    this.getCheckShow()
  },
  methods: {
    ...mapActions(["getCheckShow"]),
  }
};
</script>
<style lang="scss">
.home-page {
  padding: 44px 54px 50px 60px;
}
</style>
