<template>
  <svg
    width="14"
    height="3"
    viewBox="0 0 14 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.5H14"
      :stroke="getAssets.primaryBrandColor || '#D1202D'"
      stroke-width="2"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PlusIcon",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>