<template>
  <div class="no-freeze-spinner">
    <div id="no-freeze-spinner">
      <div>
        <i class="fas fa-th-list"></i>
        <i class="fas fa-envelope"></i>
        <i class="fas fa-calculator"></i>
        <i class="fas fa-chart-line"></i>
        <i class="fas fa-building"></i>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style>
@import "./../assets/css/loader.css";

#no-freeze-spinner > div > div {
  border: 5px solid var(--primary-color);
  animation-play-state: paused;
  border-radius: 50%;
  animation: rotate 1s infinite linear;
  border-left-color: rgba(200, 200, 200, 0.4);
}
.no-freeze-spinner #no-freeze-spinner i {
  color: var(--primary-color);
}
</style>
