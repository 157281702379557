<template>
  <div class="mortgage-tabs">
    <more-info-table
      :value="newCashToCloseBreakdown"
      :fields="cashToCloseBreakdownField"
      :footers="feeDetailItems"
      :detailSP="detailSP"
      @detail="detail"
      :current-mortgage-index="currentMortgageIndex"
      is-cash-to-close
    />
    <div
      :class="{ 'hint-active': show }"
      class="hint-cash"
      :style="{
        border: `1px solid ${$localStore.get('primary-color') || '#A6383C'}`,
      }"
    >
      <InfoTooltipIcon />

      <div class="hint-content">
        <span class="hint-ratio">
          UFMIP - FHA Upfront MIP; FF - VA Funding Fee; GF - USDA Guarantee Fee;
          SP - Single Premium
        </span>
      </div>
    </div>
    <div :class="{ 'info-chart-active': show }" class="info-chart-from-top">
      <bar-chart-horizontal
        v-model="chartData"
        :scenarioKeyMap="scenarioKeyMap"
        :isAMonth="isAMonth"
        :barPercentage="barPercentage"
        :columnSize="columnSize"
        :amountSize="amountSize"
        :columnPosition="columnPosition"
        :amountPosition="amountPosition"
        :totalCash="totalCash"
        class="chart-hz"
        is-cash-to-close
      />
      <chart-legend :legends="legends" :show="show" />
    </div>
  </div>
</template>

<script>
import MoreInfoTable from "@/components/home-pages/MoreInfoTable";
import BarChartHorizontal from "@/components/Charts/BarChartHorizontal";
import ChartLegend from "@/components/Charts/ChartLegend";
import InfoTooltipIcon from "@/components/Icons/InfoTooltipIcon";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import Shades from "@/mixins/shades";
import { REFINANCE_LOAN_TYPE } from "./../../constants";

export default {
  name: "CashToCloseTab",
  mixins: [Shades],
  components: {
    ChartLegend,
    MoreInfoTable,
    BarChartHorizontal,
    InfoTooltipIcon,
  },
  props: {
    cashToCloseBreakdown: {
      type: Array,
      default: () => [],
    },
    cashToCloseBreakdownField: {
      type: Array,
      default: () => [],
    },
    cashToCloseBreakdownChart: {
      type: Array,
      default: () => [],
    },
    allScenarios: {
      type: Array,
      default: () => [],
    },
    scenarioKeyMap: {
      type: Object,
      default: () => {},
    },
    isAMonth: {
      type: Boolean,
      default: true,
    },
    barPercentage: {
      type: Number,
      default: 0.45,
    },
    columnSize: {
      type: String,
      default: "16px",
    },
    amountSize: {
      type: String,
      default: "22px",
    },
    columnPosition: {
      type: Number,
      default: 74,
    },
    amountPosition: {
      type: Number,
      default: 48,
    },
    detailSP: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      legends: [],
    };
  },
  mounted() {
    this.legends = [
      {
        color: this.getAssets?.primaryBrandColor
          ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.6)
          : "#c34132",
        text: "Down Payment",
      },
      {
        color: this.getAssets?.primaryBrandColor
          ? this.getAssets?.primaryBrandColor
          : "#eb1c2b",
        text: "Closing Costs",
      },
      {
        color: this.getAssets?.primaryBrandColor
          ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.5)
          : "#FFCCCF",
        text: "Points",
      },
      {
        color: this.getAssets?.primaryBrandColor
          ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.7)
          : "#FFCCCF",
        text: "SP",
        info: "Private Mortgage Insurance",
      },
      {
        color: this.getAssets?.primaryBrandColor
          ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.9)
          : "#D1202D",
        text: "Prepaids",
      },
    ];
    setTimeout(() => {
      this.show = true;
    }, 100);
  },
  computed: {
    ...mapGetters(["getAssets"]),

    feeDetailItems() {
      let newCashToCloseBreakdown = cloneDeep(this.cashToCloseBreakdown);
      const index = newCashToCloseBreakdown.findIndex(
        (e) => e.cashToCloseBreakdown === "Fee Detail"
      );
      const feeDetail = newCashToCloseBreakdown[index];

      const newFeeDetail = this.cashToCloseBreakdownField.map((e) => {
        if (e.key === "cashToCloseBreakdown") {
          return {
            label: "Fee Detail",
            key: e.key,
          };
        } else {
          return {
            label: "Learn more",
            key: e.key,
            value: feeDetail[e.key],
          };
        }
      });
      return newFeeDetail;
    },

    newCashToCloseBreakdown() {
      let newCashToCloseBreakdown = cloneDeep(this.cashToCloseBreakdown);
      const indexFee = newCashToCloseBreakdown.findIndex(
        (e) => e.cashToCloseBreakdown === "Fee Detail"
      );
      newCashToCloseBreakdown.splice(indexFee, 1);

      return newCashToCloseBreakdown;
    },

    totalCash() {
      const cashItem = this.cashToCloseBreakdown.filter(
        (e) => e.cashToCloseBreakdown === "Cash to Close"
      );
      const result = cashItem.map((e) => Object.values(e))[0];
      if (result && result?.length > 0) {
        return result.filter((r) => r !== "Cash to Close");
      } else {
        return result;
      }
    },

    chartData() {
      const chartInit = [
        {
          backgroundColor: this.generateDynamicBackground(0.7),
          borderColor: this.getAssets?.primaryBrandColor
            ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.7)
            : "#c34132",
          borderWidth: 1,
        },
        {
          backgroundColor: this.generateDynamicBackground(1),
          borderColor: this.getAssets?.primaryBrandColor
            ? this.getAssets?.primaryBrandColor
            : "#eb1c2b",
          borderWidth: 1,
        },
        {
          backgroundColor: this.generateDynamicBackground(0.4),
          borderColor: this.getAssets?.primaryBrandColor
            ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.4)
            : "#FFCCCF",
          borderWidth: 1,
        },
        {
          backgroundColor: this.generateDynamicBackground(0.6),
          borderColor: this.getAssets?.primaryBrandColor
            ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.6)
            : "#FFCCCF",
          borderWidth: 1,
        },
        {
          backgroundColor: this.generateDynamicBackground(0.9),
          borderColor: this.getAssets?.primaryBrandColor
            ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.9)
            : "#D1202D",
          borderWidth: 1,
        },
      ];

      return chartInit.map((item, index) => ({
        ...item,
        data: this.cashToCloseBreakdownChart.map(
          (obj) => obj[Object.keys(obj)[index]]
        ),
      }));
    },

    currentMortgageIndex() {
      return this.allScenarios.findIndex((rec) => rec.currentMortgage);
    },
  },
  methods: {
    detail(items) {
      this.$emit("detail", items);
    },
    generateDynamicBackground(opacity) {
      let bgArr = [];

      if (this.getAssets?.loanPurposeType === REFINANCE_LOAN_TYPE) {
        for (let i = 0; i < this.allScenarios.length; i++) {
          if (this.allScenarios[i].currentMortgage) {
            bgArr.push("transparent");
          } else {
            bgArr.push(
              this.hexToRGBA(this.getAssets?.primaryBrandColor, opacity)
            );
          }
        }
      } else {
        for (let i = 0; i < this.allScenarios.length; i++) {
          bgArr.push(
            this.hexToRGBA(this.getAssets?.primaryBrandColor, opacity)
          );
        }
      }

      return bgArr;
    },
  },
};
</script>

<style scoped lang="scss">
.hint {
  &-cash {
    display: flex;
    padding: 9px 14px;
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    gap: 13px;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 40px;
    opacity: 0;
    transition: opacity 1.2s;
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-ratio {
    color: #252c32;
    font-size: 14px;
    line-height: 22px;
  }
}

.hint-active {
  opacity: 1 !important;
}
</style>
