<template>
  <div class="chart-wrapper">
    <canvas ref="chartHorizontal" />
  </div>
</template>

<script>
import Vue from "vue";
import Chart from "chart.js/auto";
import { mapGetters } from "vuex";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
Chart.register(ChartJSPluginDatalabels);

export default {
  name: "BarChartHorizontal",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    scenarioKeyMap: {
      type: Object,
      default: () => {},
    },
    isPaymentBreakdown: {
      type: Boolean,
      default: false,
    },
    isCashToClose: {
      type: Boolean,
      default: false,
    },
    totalCash: {
      type: Array,
      default: () => [],
    },
    isAMonth: {
      type: Boolean,
      default: true,
    },
    barPercentage: {
      type: Number,
      default: 0.45,
    },
    columnSize: {
      type: String,
      default: "16px",
    },
    amountSize: {
      type: String,
      default: "22px",
    },
    columnPosition: {
      type: Number,
      default: 74,
    },
    amountPosition: {
      type: Number,
      default: 48,
    },
  },
  data() {
    return {
      chart: null,
      chartLabels: {},
      chartOptions: {
        type: "bar",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "y",
          barPercentage: this.barPercentage,
          cornerRadius: 20,
          layout: {
            padding: {
              top: 20,
              right: 40,
            },
          },
          hover: {
            mode: null,
          },
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              stacked: true,
              border: {
                display: false,
              },
              grid: {
                display: false,
                borderColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
            y: {
              border: {
                display: false,
              },
              stacked: true,
              grid: {
                display: false,
                borderColor: "transparent",
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          },
        },
        plugins: [],
      },
    };
  },
  computed: {
    ...mapGetters("mortgage-estimate", ["showPayingOffDebts"]),

    chartData() {
      return this.value;
    },
  },
  watch: {
    showPayingOffDebts(newVal) {
      this.chartLabels = {};

      if (!newVal) {
        for (let rec in this.scenarioKeyMap) {
          if (rec.includes("Debt")) {
            this.chartLabels[rec] = this.scenarioKeyMap[rec].replace(
              " + Debt",
              ""
            );
          } else {
            this.chartLabels[rec] = this.scenarioKeyMap[rec];
          }
        }
      }

      if (newVal) {
        this.chartLabels = this.scenarioKeyMap;
      }
    },

    chartData: {
      handler(val) {
        if (this.chart) {
          this.chart.clear();
          this.chart.destroy();
        }
        this.handleChart(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.chartLabels = this.scenarioKeyMap;
    this.handleChart(this.chartData);
  },
  methods: {
    handleChart(val) {
      const barCharts = this.$refs.chartHorizontal;

      this.chartOptions.data.datasets = val;
      this.chartOptions.data.labels = Object.values(this.chartLabels);
      const borderRadius = 40;
      const borderRadiusAllCorners = {
        topLeft: borderRadius,
        topRight: borderRadius,
        bottomLeft: borderRadius,
        bottomRight: borderRadius,
      };
      this.chartOptions.data.datasets.map(
        (e) => (e.borderRadius = borderRadiusAllCorners)
      );
      this.chartOptions.data.datasets.map((e) => (e.borderSkipped = false));

      const totalCash = this.totalCash;

      const columnSize = `${this.columnSize} sans-serif`;
      const amountSize = `600 ${this.amountSize} sans-serif`;
      const columnPosition = val.some((e) => e.data.length === 1)
        ? this.columnPosition + 50
        : this.columnPosition;
      const amountPosition = val.some((e) => e.data.length === 1)
        ? this.amountPosition + 50
        : this.amountPosition;
      const isAMonth = this.isAMonth ? "/ month" : "Cash to Close";

      this.chartOptions.plugins = [
        {
          id: "innerBarText",
          afterDatasetsDraw(chart) {
            const {
              ctx,
              data,
              chartArea: { left },
              scales: { y },
            } = chart;

            ctx.save();

            const sub = 60;
            data.datasets.forEach((_, idx) => {
              if (idx < 3) {
                ctx.font = columnSize;
                ctx.fillStyle = "#4c4c4c";
                data.labels[idx]
                  ? ctx.fillText(
                      `${
                        data.labels[idx].substr(0, sub) +
                        (data.labels[idx].length > sub ? "..." : "")
                      }`,
                      left,
                      y.getPixelForValue(idx) - columnPosition
                    )
                  : "";

                ctx.font = amountSize;
                ctx.fillStyle = "#000000";

                totalCash[idx]
                  ? ctx.fillText(
                      `${Vue.filter("currencyAbsolute")(
                        totalCash[idx]
                      )} ${isAMonth}`,
                      left,
                      y.getPixelForValue(idx) - amountPosition
                    )
                  : ctx.fillText(
                      `$0 ${isAMonth}`,
                      left,
                      y.getPixelForValue(idx) - amountPosition
                    );
              }
            });
          },
        },
      ];

      this.chart = new Chart(barCharts, this.chartOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
  width: 100%;
}
</style>
