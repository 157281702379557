<template>
  <button
    class="outlined-btn"
    ref="outlinedBtn"
    :style="{
      color: color,
      backgroundColor: 'transparent',
      border: noBorder ? '' : `1.4px solid ${color}`,
    }"
    @mouseover="onMouseOver()"
    @mouseout="onMouseOut()"
    @click="$emit('click')"
  >
    <span>{{ text }}</span>
    <arrow-up-icon :color="iconColor" />
  </button>
</template>

<script>
import ArrowUpIcon from "@/components/Icons/ArrowUpIcon";

export default {
  name: "OutlinedButton",
  components: {
    ArrowUpIcon,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "var(--primary-color)",
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    noBackground: {
      type: Boolean,
      default: false,
    },
    textColorHover: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      iconColor: this.color,
    };
  },
  methods: {
    onMouseOver() {
      const arrow = this.$refs.outlinedBtn;
      arrow.style.color = this.textColorHover || "#fff";
      if (!this.noBackground) {
        arrow.style.backgroundColor = this.color;
      }
      this.iconColor = this.textColorHover || "#fff";
    },
    onMouseOut() {
      const arrow = this.$refs.outlinedBtn;
      arrow.style.color = this.color;
      if (!this.noBackground) {
        arrow.style.backgroundColor = "transparent";
      }
      this.iconColor = this.color;
    },
  },
};
</script>

<style lang="scss" scoped>
.outlined-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 6px;
  padding: 0 18.5px;
  height: 46px;
  border-radius: 8px;
  span {
    font-weight: 600;
  }
}
</style>
