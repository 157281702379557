<template>
  <BaseModal modalClass="fee-dialog" v-model="modal">
    <div class="fee-detail">
      <more-info-table
        class="fee-detail--table"
        v-model="feeItems"
        paddingX="33px"
        :fields="fields"
      />
      <fee-detail-footer :footerData="footerData" />
    </div>
  </BaseModal>
</template>

<script>
import MoreInfoTable from "@/components/home-pages/MoreInfoTable";
import BaseModal from "@/components/commons/atoms/BaseModal";
import FeeDetailFooter from "@/components/home-pages/FeeDetailFooter";
import camelCase from "lodash/camelCase";

export default {
  name: "FeeDetailDialog",
  components: { MoreInfoTable, BaseModal, FeeDetailFooter },
  props: {
    mortgageData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modal: false,
      footerData: {},
      fields: [
        {
          key: "description",
          label: "Less cash to close",
        },
        {
          key: "total",
          label: "Total",
        },
        {
          key: "paidBy",
          label: "Paid By",
        },
        {
          key: "aprFee",
          label: "APR Fee",
        },
        {
          key: "financed",
          label: "Financed",
        },
        {
          key: "prepaidEscrows",
          label: "Prepaid Escrows",
        },
      ],
      feeItems: [],
    };
  },
  watch: {
    modal(val) {
      if (!val) {
        this.$emit("closeDetail");
      }
    },
  },
  methods: {
    open(items) {
      this.footerData = this.mortgageData.find(
        (e) => camelCase(e.name) === items.key
      );
      this.feeItems = items.value;
      this.fields[0].label = this.footerData.name;
      this.modal = true;
    },
    close() {
      this.modal = false;
    },
  },
};
</script>

<style lang="scss">
.fee-dialog {
  .modal-content {
    width: auto !important;
    max-width: 100% !important;
  }
  .modal-dialog {
    padding: 48px;
  }
}
.fee-detail {
  width: 100%;
  &--table {
    padding: 14px 10px 0 40px;
    width: 100%;
    table thead tr th:first-child,
    table tbody tr td:first-child {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 16px !important;
    }
  }
}

@media all and (max-width: 1099px) {
  .fee-dialog:not(.layout-pdf) .fee-detail {
    &--table {
      margin-left: 14px;
      padding-left: 0;
      overflow: auto;
      width: calc(100% - 14px) !important;
    }
  }
}

@media all and (max-width: 899px) {
  .fee-dialog:not(.layout-pdf) .fee-dialog {
    .modal-dialog {
      width: 100%!important;
      max-width: calc(100% - 28px) !important;
      margin: auto;
      padding: 70px 0 30px 0;
    }
    .modal-content {
      width: auto !important;
      max-width: 100% !important;
    }
    .b-btn-close {
      right: 0;
      top: -40px;
    }
  }
}

@media all and (max-width: 599px) {
  .fee-dialog:not(.layout-pdf) .fee-detail--table table thead tr th:first-child,
  .fee-dialog:not(.layout-pdf) .fee-detail--table table tbody tr td:first-child, {
    max-width: 180px;
  }
  .fee-dialog:not(.layout-pdf) .fee-detail--table table thead tr th:first-child div {
    max-width: unset;
    white-space: unset;
  }
  .fee-dialog:not(.layout-pdf) .fee-dialog {
    .tooltip-custom .tooltip-inner {
      max-width: 315px;
    }
  }
}
</style>
