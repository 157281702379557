import TwoFactorAuth from "./components/Two-Factor-Auth";
import Layout from "./components/Layout";
import UnderConstruction from "./components/UnderConstruction";
import Logout from "./components/Logout";
import HomePage from "./pages/HomePage";

const router = [
  {
    path: "/*/:unique_key",
    component: TwoFactorAuth,
    name: "Two-Factor-Auth",
    default: true,
  },
  {
    path: "/logout",
    component: Logout,
    name: "Logout",
    default: true,
  },
  {
    path: "/",
    component: UnderConstruction,
    name: "UnderConstruction",
    default: true,
  },
  {
    path: "/home",
    component: HomePage,
    name: "HomePage",
    default: true,
  },
  {
    path: "/",
    component: Layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "mortgage-tasks",
        name: "Mortgage-Tasks",
        component: () => import("./components/Mortgage-Tasks.vue"),
        default: true,
      },
      {
        path: "mortgage-estimates",
        name: "Mortgage-Estimates",
        component: () => import("@/pages/MortgageEstimatePage.vue"),
        default: true,
      },
      {
        path: "approval-letters",
        name: "Approval-Letters",
        component: () => import("./components/Approval-Letters.vue"),
        default: true,
      },
      {
        path: "mortgage-calculator",
        name: "Mortgage-Calculator",
        component: () => import("./components/Mortgage-Calc.vue"),
        default: true,
      },
      {
        path: "credit-monitoring",
        name: "Credit-Monitoring",
        component: () => import("./components/Credit-Monitoring.vue"),
        default: true,
      },
      {
        path: "home-value-report",
        name: "Home-Value-Report",
        component: () => import('./components/HomeValueReport.vue'),
        default: true,
      },
      {
        path: "condos",
        name: "Condos",
        component: () => import('./components/Condos.vue'),
        default: true,
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/layouts/AuthLayout.vue"),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "mortgage-task1",
        name: "Mortgage-Task1",
        component: () => import("@/pages/MortgageTaskPage.vue"),
        default: true,
      },
      {
        path: "approval-letter1",
        name: "Approval-Letter1",
        component: () => import("@/pages/ApprovalLetterPage.vue"),
        default: true,
      },
      {
        path: "mortgage-calculator1",
        name: "Mortgage-Calculator1",
        component: () => import("@/pages/MortgageCalculatorPage.vue"),
        default: true,
      },
      {
        path: "credit-monitoring1",
        name: "Credit-Monitoring1",
        component: () => import("@/pages/CreditMonitoringPage.vue"),
        default: true,
      },
    ],
  },
  {
    path: "/pdf",
    component: () => import("@/pages/PdfPage.vue"),
  }
];

export default router;
