import Vue from "vue";
import moment from "moment";

Vue.filter("formatNumber", function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("capitalize", (word) => {
  return word.replace(/\b\w/g, (l) => l.toUpperCase());
});

Vue.filter("formatCardNumber", (number) => {
  return number
    .toString()
    .replace(/[^a-z0-9]+/gi, "")
    .replace(/(.{4})/g, "$1 ");
});

Vue.filter("formatDocMsgDate", (date) => {
  if (date) {
    let msgDate = moment(date).format("YYYY-MM-DD");
    let currentDate = moment().format("YYYY-MM-DD");
    if (msgDate === currentDate) return formatAMPM(new Date(date));
    else {
      return moment(date).format("YYYY-MM-DD");
    }
  } else {
    return formatAMPM(new Date());
  }
});

Vue.filter("formatMsgDate", (date) => {
  if (date) {
    return formatAMPM(new Date(date));
  } else {
    return formatAMPM(new Date());
  }
});

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

Vue.filter("getFirstName", (name) => {
  let fullName = name.split(" ");
  return fullName[0].replace(/\b\w/g, (l) => l.toUpperCase());
});

Vue.filter("getInitials", (name) => {
  let matches = name.match(/\b(\w)/g);
  return matches.join("").toUpperCase();
});

Vue.filter("currency", (value, format = "USD") => {
  if (value === undefined) {
    return "-";
  }
  const valueFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: format,
  }).format(value);
  return typeof value !== "string" ? valueFormatter : value;
});

Vue.filter("currencyNonDollar", (value, isAbsolute, format = "USD") => {
  if (value === undefined) {
    return "-";
  }
  const valueFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: format,
  })
    .format(value)
    .replace("$", "");

  return typeof value !== "string"
    ? isAbsolute
      ? valueFormatter.slice(0, -3)
      : valueFormatter
    : value;
});

Vue.filter("currencyAbsolute", (value, format = "USD") => {
  if (value === undefined) {
    return "-";
  }
  const valueFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: format,
  }).format(value);

  return typeof value !== "string" ? valueFormatter.slice(0, -3) : value;
});

Vue.filter("currencyOrPercent", (value, name, format = "USD") => {
  if (value === undefined) {
    return "-";
  }

  const valueFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: format,
    minimumFractionDigits: 2,
  }).format(value);

  if (
    name?.cashToCloseBreakdown === "Loan to Value" &&
    value === "Loan to Value"
  ) {
    return value;
  } else if (
    name?.cashToCloseBreakdown === "Loan to Value" &&
    value !== "Loan to Value"
  ) {
    return `${value}%`;
  } else if (
    (name?.cashToCloseBreakdown === "Seller Credit(s)" &&
      value !== "Seller Credit(s)" || name?.cashToCloseBreakdown === "Earnest Money" &&
      value !== "Earnest Money")
  ) {
    if (valueFormatter.slice(0, -3) !== '$0') {
      return `-${valueFormatter.slice(0, -3)}`;
    } else {
      return `${valueFormatter.slice(0, -3)}`;
    }
  } else if (typeof value !== "string") {
    return valueFormatter.slice(0, -3);
  } else {
    return value;
  }
});
