<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 0.5C1.84315 0.5 0.5 1.84315 0.5 3.5V12.5C0.5 14.1569 1.84315 15.5 3.5 15.5H12.5C14.1569 15.5 15.5 14.1569 15.5 12.5V3.5C15.5 1.84315 14.1569 0.5 12.5 0.5H3.5ZM13.8741 5.41604C14.1039 5.07139 14.0108 4.60574 13.6661 4.37597C13.3215 4.14621 12.8558 4.23934 12.626 4.58399L10.0421 8.45995L6.16611 5.87597C5.82146 5.64621 5.35581 5.73934 5.12604 6.08399L2.12604 10.584C1.89628 10.9286 1.98941 11.3943 2.33405 11.624C2.6787 11.8538 3.14435 11.7607 3.37412 11.416L5.95809 7.54007L9.83405 10.124C10.1787 10.3538 10.6444 10.2607 10.8741 9.91604L13.8741 5.41604Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'CreditMonitoringIcon',
  props: {
    color: {
      type: String,
      default: '#848692',
    },
  },
};
</script>
