<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.7 1C9.7 0.613401 9.3866 0.3 9 0.3L2.7 0.3C2.3134 0.3 2 0.613401 2 1C2 1.3866 2.3134 1.7 2.7 1.7L8.3 1.7L8.3 7.3C8.3 7.6866 8.6134 8 9 8C9.3866 8 9.7 7.6866 9.7 7.3L9.7 1ZM1.49497 9.49497L9.49497 1.49498L8.50502 0.505026L0.505025 8.50503L1.49497 9.49497Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowUpIcon",
  props: {
    color: {
      type: String,
      default: "#200E00",
    },
  },
};
</script>
