const state = {
  colorSystem: {
    primary: "#BE2530",
  },
};

const getters = {};

const mutations = {
  SET_COLOR_SYSTEM(state, payload) {
    Object.assign({}, state.colorSystem, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
