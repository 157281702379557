<template>
  <b-card @mouseover="$emit('mouseover')" @mouseout="$emit('mouseout')">
    <div class="mortgage-card">
      <div class="mortgage-card--left">
        <p class="less-white align-center">{{ item.name }}</p>
        <div>
          <p class="monthly-payment">Total Monthly Payment</p>
          <p class="amount-number">
            <span class="dollar">$</span>
            <span class="total-amount">{{
              item.totalPayment | currencyNonDollar(true)
            }}</span>
          </p>
          <p class="purchase-price">
            {{ item.purchasePrice | currencyAbsolute }} Purchase Price
          </p>
        </div>
      </div>

      <div class="mortgage-card--right d-flex">
        <div class="card-right">
          <div>
            <p class="loan-text">Loan Amount</p>
            <p class="loan-amount">{{ item.loanAmount | currencyAbsolute }}</p>
          </div>
          <div>
            <p class="loan-text">Down Payment</p>
            <p class="loan-amount">
              <span>{{ item.downPayment | currencyAbsolute }}</span>
              <span class="loan-percent">{{ item.downPaymentRate }}%</span>
            </p>
          </div>
          <div>
            <p class="loan-text">Cash to Close</p>
            <p class="loan-amount">{{ item.cashToClose | currency }}</p>
          </div>
        </div>
        <div class="card-right">
          <div>
            <p class="loan-text">Loan Type</p>
            <p class="loan-amount">{{ item.loanType }}</p>
          </div>
          <div>
            <p class="loan-text">Rate</p>
            <p class="loan-amount">{{ item.interestRate }}%</p>
          </div>
          <div>
            <p class="loan-text">APR</p>
            <p class="loan-amount">*{{ item.apr }}%</p>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "MortgageCard",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.mortgage-card {
  display: flex;
  justify-content: space-between;
  padding: 24px 36px 18px 24px;
  box-shadow: inset 0 4px 20px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  background: #15162b url("../../assets/images/icon/stacked_area_2.svg")
    no-repeat right bottom;
  transition: 0.8s ease;
  &:hover {
    background: #d1202d url("../../assets/images/icon/stacked_area_2_hover.svg")
      no-repeat right bottom;
    box-shadow: inset 0 4px 20px rgba(126, 8, 8, 0.4);
    .monthly-payment {
      color: #ffffff;
    }
  }
  &--left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 44px;
    .less-white {
      line-height: 25px;
      border-radius: 6px;
      background: #ffffff;
      width: fit-content;
      padding: 0 12px;
    }
    .monthly-payment {
      color: var(--primary-color);
      line-height: 30px;
    }

    .amount-number {
      color: #ffffff;
      line-height: 37px;
      .dollar {
        font-size: 22px;
        margin-right: 5px;
      }
      .total-amount {
        font-weight: 500;
        font-size: 35px;
        line-height: 44px;
        letter-spacing: -0.5px;
        word-spacing: -10px;
        color: #ffffff;
      }
    }
    .purchase-price {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #a5a5a9;
    }
  }

  &--right {
    gap: 64px;
    .loan-text {
      color: #a5a5a9;
      line-height: 17px;
      margin-bottom: 6px;
    }
    .loan-amount {
      font-weight: 500;
      line-height: 22px;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      .loan-percent {
        background: #ffffff;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        padding: 0 8px;
        line-height: 22px;
        text-align: right;
        color: #281d14;
      }
    }

    .card-right {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
</style>
