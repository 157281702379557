import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
import User from "./modules/user";
import HomePage from "./modules/home-page";
import MortgageEstimate from "./modules/mortgage-estimate";
import Common from "./modules/common";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
// eslint-disabled
const localStore = new SecureLS({
  encodingType: "aes",
  isCompression: true,
});

export default new Vuex.Store({
  modules: {
    User,
    "home-page": HomePage,
    "mortgage-estimate": MortgageEstimate,
    common: Common,
  },
  plugins: [
    createPersistedState({
      paths: ["User", "home-page"],
      storage: {
        getItem: (key) => localStore.get(key),
        setItem: (key, value) => localStore.set(key, value),
        removeItem: (key) => localStore.remove(key),
      },
    }),
  ],
});
