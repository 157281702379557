<template>
  <div class="header-layout">
    <img src="@/assets/images/logo.png" alt="logo" />
    <outlined-button
      text="Download report"
      color="#000000"
      text-color-hover="var(--primary-color)"
      no-border
      no-background
    />
  </div>
</template>

<script>
import OutlinedButton from "@/components/commons/atoms/OutlinedButton";

export default {
  name: "HeaderLayout",
  components: { OutlinedButton },
};
</script>

<style lang="scss">
.header-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 11.5px 60px;
  border-bottom: 1px solid #9aa2b866;
  img {
    width: 168px;
    height: 36px;
  }
}
</style>
