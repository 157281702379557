<template>
  <div class="avatar-footer">
    <img :width="width" :height="height" :src="image" alt="avatar" />
    <div v-if="badge" class="avatar-footer--badge" />
  </div>
</template>
<script>
export default {
  name: "AvatarImage",
  props: {
    image: {
      type: String,
      default: require("@/assets/images/user-icon.svg"),
    },
    badge: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "60",
    },
    height: {
      type: String,
      default: "60",
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-footer {
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  min-width: 45px !important;
  max-width: 45px !important;

  &--badge {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #0fb855;
    top: 0;
    right: 0;
    border: 1.5px solid #fff;
    border-radius: 50px;
  }
}
</style>
