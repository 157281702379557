<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.8906 1.73922L0 6.99962C0 8.10419 0.895431 8.99962 2 8.99962V16.9996H4V14.9996H6V16.9996H8V13.9996H10V16.9996H12V11.9996H14V16.9996H16V8.99962C17.1046 8.99962 18 8.10419 18 6.99962L10.1094 1.73922C9.4376 1.29135 8.5624 1.29135 7.8906 1.73922ZM9.75 4.74962V3.99962H8.25V4.74962H8C7.0335 4.74962 6.25 5.53312 6.25 6.49962V6.71884C6.25 7.52186 6.79652 8.22183 7.57556 8.41659L8.25 8.5852V9.74962H8C7.86193 9.74962 7.75 9.63769 7.75 9.49962H6.25C6.25 10.4661 7.0335 11.2496 8 11.2496H8.25V11.9996H9.75V11.2496H10C10.9665 11.2496 11.75 10.4661 11.75 9.49962V9.2804C11.75 8.47738 11.2035 7.77741 10.4244 7.58265L9.75 7.41404V6.24962H10C10.1381 6.24962 10.25 6.36155 10.25 6.49962H11.75C11.75 5.53312 10.9665 4.74962 10 4.74962H9.75ZM8.25 6.24962H8C7.86193 6.24962 7.75 6.36155 7.75 6.49962V6.71884C7.75 6.83356 7.82807 6.93356 7.93937 6.96138L8.25 7.03904V6.24962ZM9.75 8.9602V9.74962H10C10.1381 9.74962 10.25 9.63769 10.25 9.49962V9.2804C10.25 9.16568 10.1719 9.06568 10.0606 9.03786L9.75 8.9602Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Hvr-Sidebar-Icon",
  props: {
    color: {
      type: String,
      default: "#848692",
    },
  },
};
</script>