<template>
  <div :style="{ height: heightContainer }">
    <canvas ref="barCharts" />
  </div>
</template>

<script>
import Vue from "vue";
import Chart from "chart.js/auto";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import { mapGetters } from "vuex";
import Shades from "@/mixins/shades";
import { REFINANCE_LOAN_TYPE } from "./../../constants";
Chart.register(ChartJSPluginDatalabels);

export default {
  name: "BarChartRounded",
  mixins: [Shades],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      default: 450,
    },
    heightContainer: {
      type: [Number, String],
      default: null,
    },
    activeId: {
      type: Number,
      default: null,
    },
    currentMortgageIndex: {
      type: Number,
      default: 0,
    },
    barPercentage: {
      type: Number,
      default: 0.7,
    },
    paddingTop: {
      type: Number,
      default: 36,
    },
    inPage: {
      type: Boolean,
      default: false,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [
        "Less cash to close",
        "Fastest payoff",
        "Lowest interest rate",
      ],
    },
  },
  data() {
    return {
      chart: null,
      chartOptions: {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: "",
              data: [],
              backgroundColor: [
                this.$localStore.get("primary-color") || "#c34132",
                this.$localStore.get("primary-color") || "#c34132",
                this.$localStore.get("primary-color") || "#c34132",
              ],
              borderColor: this.$localStore.get("primary-color") || "#c34132",
              borderRadius: 30,
              borderSkipped: false,
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          barPercentage: this.barPercentage,
          cornerRadius: 20,
          layout: {
            padding: {
              top: this.paddingTop,
            },
          },
          hover: {
            mode: null,
          },
          animations: {
            backgroundColor: {
              type: "color",
              duration: 500,
              easing: "linear",
            },
          },
          plugins: {
            datalabels: {
              formatter(value) {
                return Vue.filter("currencyAbsolute")(value);
              },
              align: "top",
              anchor: "end",
              color: "black",
              labels: {
                title: {
                  font: {
                    weight: 500,
                    size: 16,
                  },
                },
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 16,
                  weight: 400,
                },
                // autoSkipPadding: 0,
                autoSkip: false,
                maxRotation: 0,
                color: "#000000",
                callback: (value) => {
                  if (
                    ((window.innerWidth < 1550 && window.innerWidth > 1264) ||
                      window.innerWidth < 900) &&
                    this.inPage &&
                    this.labels.length > 2
                  ) {
                    return this.labels[value].split(" ");
                  } else if (
                    window.innerWidth < 768 &&
                    this.inModal &&
                    this.labels.length > 2
                  ) {
                    return this.labels[value].split(" ");
                  } else if (
                    window.innerWidth < 479 &&
                    this.inModal &&
                    this.labels.length === 2
                  ) {
                    return this.labels[value].split(" ");
                  } else {
                    const sub = 25;
                    return (
                      this.labels[value].substr(0, sub) +
                      (this.labels[value].length > sub ? "..." : "")
                    );
                  }
                },
              },
            },
            y: {
              border: {
                display: false,
              },
              grid: {
                display: false,
                borderColor: "transparent",
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
              afterFit: function (scale) {
                const heightRatio = 0.95;
                scale.min =
                  scale.min - ((scale.max - scale.min) * (1 - heightRatio)) / 2;
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getAssets"]),

    mortgageData: {
      get() {
        return this.value;
      },
    },
  },
  watch: {
    activeId(val) {
      const meta = this.chart.getDatasetMeta(0).data;

      if (meta.length > 0) {
        meta.forEach((e, i) => {
          if (this.getAssets?.loanPurposeType === REFINANCE_LOAN_TYPE) {
            if (i !== this.currentMortgageIndex) {
              if (val !== i && val !== null) {
                e.$context.dataset.backgroundColor[i] = this.getAssets
                  .primaryBrandColor
                  ? this.hexToRGBA(this.getAssets.primaryBrandColor, 0.6)
                  : "#FFCCCF";
              } else {
                e.$context.dataset.backgroundColor[i] =
                  this.$localStore.get("primary-color") || "#c34132";
              }
            }
          } else {
            if (val !== i && val !== null) {
              e.$context.dataset.backgroundColor[i] = this.getAssets
                .primaryBrandColor
                ? this.hexToRGBA(this.getAssets.primaryBrandColor, 0.6)
                : "#FFCCCF";
            } else {
              e.$context.dataset.backgroundColor[i] =
                this.$localStore.get("primary-color") || "#c34132";
            }
          }
        });
        this.chart.update();
      }
    },
    mortgageData: {
      handler(val) {
        this.chartOptions.data.datasets[0].data = val;
        this.chart.update();
      },
    },
    labels(val) {
      this.chartOptions.data.labels = val;
      this.chart.update();
    },
    currentMortgageIndex(val) {
      if (this.getAssets?.loanPurposeType === REFINANCE_LOAN_TYPE) {
        this.chartOptions.data.datasets[0].backgroundColor[val] = "transparent";
        this.chart.update();
      }
    },
  },
  mounted() {
    this.chartOptions.data.datasets[0].data = this.mortgageData;
    this.chartOptions.data.labels = this.labels;
    const barCharts = this.$refs.barCharts;
    barCharts.height = this.height;
    this.chart = new Chart(barCharts, this.chartOptions);

    this.$nextTick(() => {
      if (this.getAssets?.loanPurposeType === REFINANCE_LOAN_TYPE) {
        this.chartOptions.data.datasets[0].backgroundColor[
          this.currentMortgageIndex
        ] = "transparent";
        this.chart.update();
      }
    });
  },
};
</script>
