import axios from "@/api-service";
import mockData from "@/mock/Check_Show_Response.json";

const state = {
  mode: {
    stored: false,
    showWelcome: true,
    showMortgageEstimateMenu: true,
    mtgEstimateCount: 0,
  },

  showCount: 0,
};

const getters = {
  showWelcome: (state) => state.mode.showWelcome,
  showMortgageEstimateMenu: (state) => state.mode.showMortgageEstimateMenu,
  mtgEstimateCount: (state) => {
    if (state.showCount >= 2) {
      return 0;
    }
    return state.mode.mtgEstimateCount;
  },
  showCount: (state) => state.showCount,
};

const actions = {
  async getCheckShow({ state, commit }) {
    if (!state.mode.stored) {
      try {
        const res = await axios.get("/scenarios");
        console.log("res ", res);
      } catch (e) {
        console.log(e);
        commit("setStored", true);
        commit("setShowWelcome", mockData.showWelcome);
        commit(
          "setShowMortgageEstimateMenu",
          mockData.showMortgageEstimateMenu
        );
      }
    }
  },
  setShowWelcome({ commit }, data) {
    commit("setShowWelcome", data);
  },
  setShowMortgageEstimateMenu({ commit }, data) {
    commit("setShowMortgageEstimateMenu", data);
  },
};

const mutations = {
  setStored: (state, payload) => (state.mode.stored = payload),
  setShowWelcome: (state, payload) => (state.mode.showWelcome = payload),
  setShowMortgageEstimateMenu: (state, payload) =>
    (state.mode.showMortgageEstimateMenu = payload),
  setMortgageEstimateCount: (state, payload) => {
    if (payload === 0 || state.showCount > 2) {
      state.mode.mtgEstimateCount = 0;
      return;
    }
    if (state.showCount <= 2) {
      state.mode.mtgEstimateCount = state.showCount + 1;
    } else {
      state.mode.mtgEstimateCount = 0;
    }
  },
  setShowCount: (state) => state.showCount++,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
