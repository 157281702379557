<template>
  <div :style="cssProps" class="d-flex h-100 w-100">
    <loader v-if="loading" />
    <router-view></router-view>
    <div id="intro-farm"></div>
  </div>
</template>

<script>
import "./filters.js";
import Loader from "./components/Loader.vue";
import { mapGetters } from "vuex";
import Shades from "../src/mixins/shades";

export default {
  name: "App",
  mixins: [Shades],
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.primaryColor = this.$localStore.get("primary-color") || "#A6383C";

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // Check if a new element was added to the head section
        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
          const addedNodes = Array.from(mutation.addedNodes);

          // Check if any of the added nodes are script tags
          const newScripts = addedNodes.filter(
            (node) => node.tagName === "SCRIPT"
          );

          // Execute your function for each new script tag
          newScripts.forEach((script) => {
            // Call your function here
            this.handleNewScriptTag(script);
          });
        }
      });
    });

    // Start observing the changes in the head section
    observer.observe(document.head, { childList: true });
  },
  computed: {
    ...mapGetters(["getAssets"]),

    cssProps() {
      return {
        "--primary-color": this.getAssets?.primaryBrandColor || "#c34132",
        "--bg-primary-color": this.getAssets?.primaryBrandColor
          ? this.newShade(this.getAssets?.primaryBrandColor, 200)
          : "#FFCCCF",
        "--blink-shade": this.getAssets?.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets?.primaryBrandColor, 1.2)
          : "#eb1c2b",
        "--shade-one": this.getShadesOfColor(
          this.getAssets?.primaryBrandColor || "#c34132",
          -0.1
        ),
        "--shade-two": this.getShadesOfColor(
          this.getAssets?.primaryBrandColor || "#c34132",
          -0.3
        ),
        "--shade-three": this.getShadesOfColor(
          this.getAssets?.primaryBrandColor || "#c34132",
          0
        ),
        "--shade-four": this.getShadesOfColor(
          this.getAssets?.primaryBrandColor || "#c34132",
          -0.2
        ),
        "--poor": this.getAssets?.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets?.primaryBrandColor, 1.4)
          : "#FFCCCF",
        "--fair": this.getAssets?.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets?.primaryBrandColor, 0.9)
          : "#FF7B84",
        "--good": this.getAssets?.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets?.primaryBrandColor, -0.1)
          : "#D1202D",
        "--very-good": this.getAssets?.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets?.primaryBrandColor, -0.3)
          : "#D1202D",
        "--exceptional": this.getAssets?.primaryBrandColor
          ? this.getShadesOfColor(this.getAssets?.primaryBrandColor, -0.5)
          : "#94000B",
        "--hvr-slider-bg": this.getShadesOfColor(
          this.getAssets?.primaryBrandColor || "#FFF2F3",
          0.9
        ),
      };
    },
  },
  created() {
    const script = document.createElement("script");
    script.src = "/config.js";
    document.head.appendChild(script);
    const script2 = document.createElement("script");
    script2.src = "/loader_only.js";
    document.head.appendChild(script2);
  },
  methods: {
    handleNewScriptTag(script) {
      let url = "";
      if (script.src.includes("iojs") && script.src.includes("logo.js")) {
        if (process.env.NODE_ENV == "development")
          url = "https://clientportalapi-dev.candidcrm.com/iojs/";
        else if (process.env.NODE_ENV == "staging")
          url = "https://clientportalapi-staging.candidcrm.com/iojs/";
        else url = "https://clientportalapi.candidcrm.com/iojs/";
        script.src = script.src.replace(url, "https://first.iovation.com/");
        setTimeout(() => {
          console.clear();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/vendors/bootstrap-vue/index.scss";
@import "./assets/css/app.css";
@import "./assets/css/custom.css";
</style>
