<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0.5V14.5M0 7.5H14"
      :stroke="getAssets.primaryBrandColor || '#D1202D'"
      stroke-width="2"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PlusIcon",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>