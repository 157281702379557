<template>
  <div class="fee-detail-footer">
    <div class="fee-detail-footer--left">
      <p class="opacity-item borrow-paid">Borrower Paid</p>
      <p>
        <span
          class="left-dollar"
          v-if="footerData.cashToCloseBreakdown.borrowerPaid >= 0"
          >$</span
        >
        <span class="left-amount">{{
          footerData.cashToCloseBreakdown.borrowerPaid | currencyNonDollar(true)
        }}</span>
      </p>
    </div>

    <div class="fee-detail-footer--center">
      <div class="center-item">
        <p class="opacity-item">Points</p>
        <p class="detail-amount">
          {{ footerData.cashToCloseBreakdown.points | currencyAbsolute }}
        </p>
      </div>
      <div class="center-item">
        <p class="opacity-item">Prepaid Interest</p>
        <p class="detail-amount">
          {{
            footerData.cashToCloseBreakdown.prepaidInterest | currencyAbsolute
          }}
        </p>
      </div>
      <div class="center-item">
        <p class="opacity-item">
          <span>{{
            footerData.cashToCloseBreakdown.ufmipFfGfSpLabel || "UFMIP/FF/GF/SP"
          }}</span>
          <info-tooltip :info="ufmipFfGfSp" />
        </p>
        <p class="detail-amount">
          {{ footerData.cashToCloseBreakdown.ufmipFfGfSp | currencyAbsolute }}
        </p>
      </div>
    </div>

    <div class="fee-detail-footer--right">
      <div class="right-item">
        <p class="opacity-item">Lender Paid</p>
        <p class="detail-amount">
          {{ footerData.cashToCloseBreakdown.lenderPaid | currencyAbsolute }}
        </p>
      </div>
      <div
        v-if="getAssets?.loanPurposeType !== refinanceLoanType"
        class="right-item"
      >
        <p class="opacity-item">Seller Paid</p>
        <p class="detail-amount">
          {{ footerData.cashToCloseBreakdown.sellerPaid | currencyAbsolute }}
        </p>
      </div>
      <div class="right-item">
        <p class="opacity-item">
          {{
            getAssets?.loanPurposeType === refinanceLoanType
              ? "Lender Credit"
              : "Earnest Money"
          }}
        </p>
        <p class="detail-amount">
          {{ footerData.cashToCloseBreakdown.earnestMoney | currencyAbsolute }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InfoTooltip from "@/components/Icons/InfoToolTip";
import { REFINANCE_LOAN_TYPE } from "./../../constants";

export default {
  name: "FeeDetailFooter",
  components: {
    InfoTooltip,
  },
  props: {
    footerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      refinanceLoanType: REFINANCE_LOAN_TYPE,
      ufmipFfGfSp:
        "<strong>Upfront Mortgage Insurance Premium (UFMIP)</strong> - A Federal Housing Administration mortgage insurance charge paid by homebuyer's andtypically financed into the loan amount. <br/> <br /> <strong>Funding Fee (FF)</strong> - A fee charged by the Department of Veterans Affairs, paid by VA homebuyer's and typically financed into the loan. The fee varies based on down payment amount and number of prior VA loans originated. <br /> <br /> <strong>Guarantee Fee (GF)</strong> (not girlfriend or gluten-free) - A fee charged by the USDA, paid by homebuyer's and typically financed into the loan amount. <br/> <br /> <strong>Single Premium (SP)</strong> - Mortgage insurance paid at closing and as , rather than monthly.",
    };
  },
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>

<style lang="scss" scoped>
.fee-detail-footer {
  background: #15162b;
  border-radius: 0 0 9px 9px;
  display: flex;
  justify-content: space-between;
  padding: 24px 34px 12px 40px;
  gap: 154px;
  min-width: max-content;
  width: 100%;

  .borrow-paid {
    margin-bottom: 24px;
  }

  p {
    color: #ffffff;
  }

  .opacity-item {
    opacity: 0.7;
  }

  .detail-amount {
    font-weight: 500;
  }

  .center-item,
  .right-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 64px;
    margin-bottom: 8px;
  }
  &--center {
    min-width: 254px;
  }
  &--right {
    min-width: 216px;
  }
  &--left {
    min-width: 100px;
    .left-amount {
      font-weight: 500;
      font-size: 35px;
      line-height: 52.5px;
      letter-spacing: -0.5px;
      opacity: 1;
    }

    .left-dollar {
      font-weight: 500;
      font-size: 22px;
      line-height: 33px;
      letter-spacing: -0.5px;
    }
  }
}

@media all and (max-width: 1079px) {
  .fee-dialog:not(.layout-pdf) .fee-detail-footer {
    gap: unset;
  }
}

@media all and (max-width: 899px) {
  .fee-dialog:not(.layout-pdf) .fee-detail-footer {
    flex-direction: column;
    padding: 24px 40px;

    &--left {
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    &--center {
      padding-top: 28px;
    }

    .center-item,
    .right-item {
      gap: unset;
    }
  }
}

@media all and (max-width: 600px) {
  .fee-dialog:not(.layout-pdf) .fee-detail-footer {
    padding: 24px 14px;
    .borrow-paid {
      margin-bottom: 4px;
    }
  }
}
</style>
