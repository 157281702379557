<template>
  <div class="w-100">
    <loader v-if="loading" />
    <div class="wrapper">
      <sidebar></sidebar>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { eventBus } from "./../event-bus";
import Sidebar from "./Sidebar.vue";
import Loader from "./Loader";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Layout",
  components: {
    Sidebar,
    Loader,
  },
  data() {
    return {
      loading: false,
      primaryColor: "",
    };
  },
  computed: {
    ...mapGetters(["getOrgId", "getUser", "getAssets"]),
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.getPortalAssets();
        this.getPresentationSettings();
      },
    },
  },
  created() {
    eventBus.$on("loader-status", (data) => {
      this.loading = data;
    });

    this.primaryColor = this.getAssets.primaryBrandColor || "#A6383C";
  },
  methods: {
    ...mapActions([
      "fetchAssets",
      "fetchTabs",
      "fetchBorrowerName",
      "fetchCoBorrowerName",
      "fetchPlaidData",
    ]),
    ...mapActions("mortgage-estimate", ["getPresentationSettings"]),

    getPortalAssets() {
      this.$http
        .get(
          "/account/GetAsset/" + this.getUser.prospectId + "/" + this.getOrgId
        )
        .then((response) => {
          let resp = response.data;
          if (resp.status === 511) this.$router.push("/");

          if (resp.status !== 511) {
            this.fetchAssets(resp.assetData);
            this.fetchBorrowerName(resp.assetData.borrowerName);
            this.fetchCoBorrowerName(resp.assetData.coBorrowerName);
            this.fetchPlaidData(resp.plaidData);

            let theTabs = resp.assetData.clientPortalDefaultTabs;
            let hvrTab = "Home-Value-Report";
            let getHVRIndex = theTabs.findIndex((el) => el === hvrTab);

            if (getHVRIndex >= 0) {
              theTabs.splice(getHVRIndex, 1);
              theTabs.unshift(hvrTab);
            }

            this.fetchTabs(theTabs);

            const favicon = document.getElementById("favicon");
            favicon.setAttribute("href", resp.assetData?.roundColorTeamLogo);

            let primary_color = resp.assetData.primaryBrandColor || "#A6383C";
            this.$localStore.set("primary-color", primary_color);

            this.$localStore.set("default-tabs", theTabs);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
@import "./../assets/css/app.css";
@import "./../assets/css/custom.css";

.btn.btn-primary {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

*::-webkit-scrollbar {
  width: 4px !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color) !important;
  border: 4px solid var(--primary-color) !important;
}
</style>
