<template>
  <div class="mortgage-tabs">
    <more-info-table
      :value="paymentBreakdown"
      :fields="paymentBreakdownField"
      :current-mortgage-index="currentMortgageIndex"
      :all-scenarios="allScenarios"
      is-payment-breakdown
    />
    <div :class="{ 'info-chart-active': show }" class="info-chart-from-bottom">
      <bar-chart-horizontal
        v-model="chartData"
        :scenarioKeyMap="scenarioKeyMap"
        :barPercentage="barPercentage"
        :columnSize="columnSize"
        :amountSize="amountSize"
        :columnPosition="columnPosition"
        :amountPosition="amountPosition"
        :totalCash="totalCash"
        class="chart-hz"
        is-payment-breakdown
      />
      <chart-legend :legends="legends" :show="show" />
    </div>
  </div>
</template>

<script>
import MoreInfoTable from "@/components/home-pages/MoreInfoTable";
// import MoreInfoPaymentBreakdownTable from "@/components/home-pages/MoreInfoPaymentBreakdownTable";
import BarChartHorizontal from "@/components/Charts/BarChartHorizontal";
import ChartLegend from "@/components/Charts/ChartLegend";
import { mapGetters } from "vuex";
import Shades from "@/mixins/shades";
import { REFINANCE_LOAN_TYPE } from "./../../constants";

export default {
  name: "PaymentBreakdownTab",
  mixins: [Shades],
  components: {
    ChartLegend,
    MoreInfoTable,
    // MoreInfoPaymentBreakdownTable,
    BarChartHorizontal,
  },
  props: {
    paymentBreakdown: {
      type: Array,
      default: () => [],
    },
    paymentBreakdownField: {
      type: Array,
      default: () => [],
    },
    paymentBreakdownChart: {
      type: Array,
      default: () => [],
    },
    allScenarios: {
      type: Array,
      default: () => [],
    },
    scenarioKeyMap: {
      type: Object,
      default: () => {},
    },
    barPercentage: {
      type: Number,
      default: 0.45,
    },
    columnSize: {
      type: String,
      default: "16px",
    },
    amountSize: {
      type: String,
      default: "22px",
    },
    columnPosition: {
      type: Number,
      default: 74,
    },
    amountPosition: {
      type: Number,
      default: 48,
    },
  },
  data() {
    return {
      show: false,
      legends: [],
    };
  },
  watch: {
    showPayingOffDebts(newVal) {
      if (newVal) {
        this.legends.push({
          color: this.getAssets?.primaryBrandColor
            ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 1.1)
            : "#D1202D",
          text: "Additional Debt(s)",
        });
      } else {
        let the_index = this.legends.findIndex(
          (el) => el.text === "Additional Debt(s)"
        );
        console.log(the_index);
        if (the_index >= 0) this.legends.splice(the_index, 1);
      }
    },
  },
  mounted() {
    this.legends = [
      {
        color: this.getAssets?.primaryBrandColor
          ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.7)
          : "#c34132",
        text: "Principle & Interest",
      },
      {
        color: this.getAssets?.primaryBrandColor
          ? this.getAssets?.primaryBrandColor
          : "#eb1c2b",
        text: "Taxes",
      },
      {
        color: this.getAssets?.primaryBrandColor
          ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.5)
          : "#FFCCCF",
        text: "HOI",
        info: "Homeowner's Insurance",
      },
      {
        color: this.getAssets?.primaryBrandColor
          ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.9)
          : "#D1202D",
        text: "PMI",
        info: "Private Mortgage Insurance",
      },
    ];

    if (this.showPayingOffDebts) {
      this.legends.push({
        color: this.getAssets?.primaryBrandColor
          ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 1.1)
          : "#D1202D",
        text: "Additional Debt(s)",
      });
    } else {
      let the_index = this.legends.findIndex(
        (el) => el.text === "Additional Debt(s)"
      );

      if (the_index >= 0) this.legends.splice(the_index, 1);
    }

    setTimeout(() => {
      this.show = true;
    }, 300);
  },
  computed: {
    ...mapGetters(["getAssets"]),
    ...mapGetters("mortgage-estimate", ["showPayingOffDebts"]),

    totalCash() {
      const paymentItem = this.paymentBreakdown.filter(
        (e) =>
          e.paymentBreakdown === "Total Payment" ||
          e.paymentBreakdown === "Total Int & MI"
      );
      const result = paymentItem.map((e) => Object.values(e))[0];

      if (result && result.length > 0) {
        let final_result = result.filter(
          (r) => r !== "Total Payment" && r !== "Total Int & MI"
        );

        return !this.showPayingOffDebts
          ? final_result
          : final_result.map(
              (rec, index) =>
                rec + this.chartData[this.chartData.length - 1].data[index]
            );
      } else {
        return result;
      }
    },
    chartData() {
      const chartInit = this.showPayingOffDebts
        ? [
            {
              backgroundColor: this.generateDynamicBackground(0.7),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.7)
                : "#c34132",
              borderWidth: 1,
            },
            {
              backgroundColor: this.generateDynamicBackground(1),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.getAssets?.primaryBrandColor
                : "#eb1c2b",
              borderWidth: 1,
            },
            {
              backgroundColor: this.generateDynamicBackground(0.5),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.5)
                : "#FFCCCF",
              borderWidth: 1,
            },
            {
              backgroundColor: this.generateDynamicBackground(0.9),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.9)
                : "#D1202D",
              borderWidth: 1,
            },
            {
              backgroundColor: this.generateDynamicBackground(1.1),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 1.1)
                : "#D1202D",
              borderWidth: 1,
            },
          ]
        : [
            {
              backgroundColor: this.generateDynamicBackground(0.7),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.7)
                : "#c34132",
              borderWidth: 1,
            },
            {
              backgroundColor: this.generateDynamicBackground(1),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.getAssets?.primaryBrandColor
                : "#eb1c2b",
              borderWidth: 1,
            },
            {
              backgroundColor: this.generateDynamicBackground(0.5),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.5)
                : "#FFCCCF",
              borderWidth: 1,
            },
            {
              backgroundColor: this.generateDynamicBackground(0.9),
              borderColor: this.getAssets?.primaryBrandColor
                ? this.hexToRGBA(this.getAssets?.primaryBrandColor, 0.9)
                : "#D1202D",
              borderWidth: 1,
            },
          ];

      return chartInit.map((item, index) => ({
        ...item,
        data: this.paymentBreakdownChart.map(
          (obj) => obj[Object.keys(obj)[index]]
        ),
      }));
    },
    currentMortgageIndex() {
      return this.allScenarios.findIndex((rec) => rec.currentMortgage);
    },
  },
  methods: {
    generateDynamicBackground(opacity) {
      let bgArr = [];

      if (this.getAssets?.loanPurposeType === REFINANCE_LOAN_TYPE) {
        for (let i = 0; i < this.allScenarios.length; i++) {
          if (this.allScenarios[i].currentMortgage) {
            bgArr.push("transparent");
          } else {
            bgArr.push(
              this.hexToRGBA(this.getAssets?.primaryBrandColor, opacity)
            );
          }
        }
      } else {
        for (let i = 0; i < this.allScenarios.length; i++) {
          bgArr.push(
            this.hexToRGBA(this.getAssets?.primaryBrandColor, opacity)
          );
        }
      }

      return bgArr;
    },
  },
};
</script>
