<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0.5C3.13395 0.5 0 3.63406 0 7.5C0 11.3662 3.13395 14.5 7 14.5C10.8661 14.5 14 11.3662 14 7.5C14 3.63406 10.8661 0.5 7 0.5ZM7 4C7.48327 4 7.875 4.39178 7.875 4.875C7.875 5.35844 7.48327 5.75 7 5.75C6.51673 5.75 6.125 5.35844 6.125 4.875C6.125 4.39178 6.51673 4 7 4ZM8.3125 11H5.6875C5.44589 11 5.25 10.8043 5.25 10.5625C5.25 10.3209 5.44589 10.125 5.6875 10.125H6.125V7.5H5.6875C5.44589 7.5 5.25 7.30433 5.25 7.0625C5.25 6.82089 5.44589 6.625 5.6875 6.625H7.4375C7.67911 6.625 7.875 6.82089 7.875 7.0625V10.125H8.3125C8.55411 10.125 8.75 10.3209 8.75 10.5625C8.75 10.8043 8.55411 11 8.3125 11Z"
      fill="#6D6D6D"
    />
  </svg>
</template>

<script>
export default {
  name: "TooltipIcon",
};
</script>